import '@/styles/globals.scss'
import Head from 'next/head'
import { PromotionsProvider } from '@saatva-bits/pattern-library.modules.promotions'
import { LaunchDarklyProvider } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.context'
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'
import { SharedCartServiceProvider } from '@saatva-bits/pattern-library.modules.cart'
import { PrismicPreview } from '@prismicio/next'
import { FavoriteProvider } from '@saatva-bits/pattern-library.modules.favorite'

const repositoryName = 'saatva-site-content'

export default function App({ Component, pageProps }) {
    if (pageProps.skipBaseProviders) {
        // static pages
        return <Component {...pageProps} />
    } else {
        // server side pages
        return (
            /* Wrap with any providers all pages need */
            <LaunchDarklyProvider contextData={pageProps.launchDarklyContextData}>
                <SharedCartServiceProvider
                    apiRoute={process.env.NEXT_PUBLIC_CART_URL}
                    initialCartCount={pageProps.cartData?.cartQuantity}
                >
                    <PromotionsProvider contextData={pageProps.promotionsContextData}>
                        <FavoriteProvider>
                            <ErrorBoundary {...pageProps}>
                                <Head>
                                    {pageProps?.assetsToPreload?.map(({ href, media }) => (
                                        <link key={`preload-${href}`} rel="preload" as="image" href={href} media={media} />
                                    ))}
                                </Head>
                                <Component {...pageProps} />
                                <PrismicPreview repositoryName={repositoryName} />
                            </ErrorBoundary>
                        </FavoriteProvider>
                    </PromotionsProvider>
                </SharedCartServiceProvider>
            </LaunchDarklyProvider>
        )
    }
}
