import { pushDataLayerEvent } from './datalayer'

const getLastUrlSegment = () => {
    return window.location.pathname.split('/').pop()
}

const handleCordialEmailSubmission = async (email, validationHandler) => {
    const subscribeValues = {
        email: email
    }

    let signupBtn = document.activeElement
    let footer = document.querySelector('footer[class*="footer"]') || document.querySelector('footer[data-selector*="main-footer-section"]')
    let isInFooter = footer.contains(signupBtn)

    try {
        if (subscribeValues.email === '') {
            throw new Error('Please submit an email.')
        }

        validationHandler && validationHandler('success', 'You\'ve been subscribed!')
        const signupLocation = (isInFooter ? 'storefrontFooter' : `${getLastUrlSegment()}`)
        sendCordialEmailSubmissionEvents(email, signupLocation)
    } catch (error) {
        console.error(`There was an error creating the Cordial contact with the message: ${error.message}`)
        window?.ineum?.('subscribedUserEmailError', error, { msg: error.message })
        validationHandler('error', error.message)
    }
}

const sendCordialEmailSubmissionEvents = (email, signupLocation) => {
    pushDataLayerEvent({
        event: 'emailSignup',
        email: `${email}`,
        signupLocation
    })
}

const handleCordialBisaInlineSubmission = (url, email, sku, productName, cordialImageSrc) => {

    // The following crdl('contact'...) call registers the user with Cordial.
    // The contact data will be empty, since the BISA email is independent of the marketing signup.
    const auth_data = { email }
    const contact_data = {}
    window.crdl('contact', auth_data, contact_data)

    // The following crdl('event'...) call signs up the user (registered above) for the BISA email alerts.
    const eventData = {
        sku,
        productName,
        url,
    }
    cordialImageSrc && (eventData.image = cordialImageSrc)
    window.crdl('event', 'back_in_stock_signup', eventData)

    // This is necessary since the cordial event does NOT push anything to our data layer (unlike in lightbox, which does so via an integration).
    pushDataLayerEvent({
        event: 'PrimerSubmit',
        PrimerBoxName: 'Back In Stock Signup'
    })
}

export {
    handleCordialEmailSubmission,
    sendCordialEmailSubmissionEvents,
    handleCordialBisaInlineSubmission
}
